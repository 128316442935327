
import mixins from 'vue-typed-mixins';
import { mapGetters, mapMutations } from 'vuex';
import { SelectOption, VForm } from '@/types';
import NotificationMixin from '@/mixins/NotificationMixin';
import ProfileManagementService from '@/common/services/company-reborn/ProfileManagementService';
import MasterService from '@/common/services/MasterService';
import {
  ProfileManagement, MasterParams, SupplierParams,
} from '@/resources/profile-management/types';
import locales from '@/resources/profile-management/locales';
import HierarchyBusinessLevelSectionForm from '@/resources/profile-management/components/forms/hierarchy-business-level-section/HierarchyBusinessLevelSectionForm.vue';
import InboundPortalPermissionSectionForm from '@/resources/profile-management/components/forms/inbound-portal-permission-section/InboundPortalPermissionSectionForm.vue';
import AdminPermissionSectionForm from '@/resources/profile-management/components/forms/admin-permission-section/AdminPermissionSectionForm.vue';
import ProfileNameSectionForm from '@/resources/profile-management/components/forms/profile-name-section/ProfileNameSectionForm.vue';

type ProfileManagementPayload = {
  id?: string;
  name: string;
  clientType: 'master' | 'supplier';
  clientParams: MasterParams | SupplierParams;
  permissions: string[];
}

const formDataBase = {
  id: undefined,
  name: '',
  clientType: 'master',
  clientParams: {
    masterId: '',
    companyGroups: [],
    companies: [],
    establishments: [],
  } as MasterParams | SupplierParams,
  permissions: [],
} as ProfileManagementPayload;

export default mixins(NotificationMixin).extend({
  name: 'ProfileManagementForm',

  props: {
    payload: {
      type: Object as () => ProfileManagementPayload,
      required: false,
    },
    autoload: {
      type: Boolean,
      default: false,
    },
    canSubmit: {
      type: Boolean,
      default: true,
    },
  },

  i18n: {
    messages: locales,
  },

  components: {
    HierarchyBusinessLevelSectionForm,
    InboundPortalPermissionSectionForm,
    AdminPermissionSectionForm,
    ProfileNameSectionForm,
    SupplierPortalSectionForm:
    () => import('@/resources/profile-management/components/forms/supplier-portal-section/SupplierPortalSectionForm.vue'),

    SupplierManagementPermissionSectionForm:
      () => import('@/resources/profile-management/components/forms/supplier-management-permission-section/SupplierManagementPermissionSectionForm.vue'),
  },

  data() {
    const formData: ProfileManagementPayload = this.payload?.id
      ? JSON.parse(JSON.stringify(this.payload))
      : formDataBase;

    return {
      formData: formData as ProfileManagementPayload,
      bankAdmin: {
        permissions: [] as SelectOption[],
        permissionsSelected: [] as string[],
      },
      bank: {
        permissions: [] as SelectOption[],
        permissionsSelected: [] as string[],
      },
      supplierManagement: {
        permissions: [] as SelectOption[],
        permissionsSelected: [] as string[],
      },
      hierarchyBusinessLevels: [] as {
        id: string;
        level: 'companyGroup' | 'company' | 'establishment';
      }[],
      supplier: {
        permissions: [] as SelectOption[],
        permissionsSelected: [] as string[],
        masters: [] as SelectOption[],
        mastersSelected: [] as string[],
      },
      profileNameValidation: {
        valid: false,
        pending: false,
        profileName: this.payload?.name || '',
      },
      permissionIsRequired: true,
    };
  },

  async mounted() {
    this.hierarchyBusinessLevelPayload();

    if (this.autoload) {
      const loadClientTypeMaster = async () => {
        await Promise.all([
          this.getBankAdminPermissions(),
          this.getBankPermissions(),
          this.getSupplierManagementPermissions(),
        ]);
      };

      const loadClientTypeSupplier = async () => {
        await Promise.all([
          this.getSupplierMasters(),
          this.getSupplierPermissions(),
        ]);
      };

      try {
        this.handleLoading(true);

        if (this.isUpdate) {
          if (this.isClientTypeMaster) {
            await loadClientTypeMaster();
          }
          if (this.isClientTypeSupplier) {
            await loadClientTypeSupplier();
          }
        } else {
          await Promise.all([
            loadClientTypeMaster(),
            loadClientTypeSupplier(),
          ]);
        }
      } catch {
        this.failRequest();
      }
      this.handleLoading(false);
    }
  },

  computed: {
    ...mapGetters({
      isMaster: 'master/isMaster',
      masterId: 'master/masterId',
      isSupplier: 'supplier/isSupplier',
      supplierId: 'supplier/supplierId',
      supplierMasters: 'supplier/supplierMasters',
    }),

    isUpdate(): boolean {
      return !!this.payload?.id;
    },

    isMultipleProducts(): boolean {
      return this.isMaster && this.isSupplier;
    },

    isClientTypeSupplier(): boolean {
      return this.formData.clientType === 'supplier';
    },

    isClientTypeMaster(): boolean {
      return this.formData.clientType === 'master';
    },

    permissionsMaster(): string[] {
      return [
        ...this.bank.permissionsSelected,
        ...this.bankAdmin.permissionsSelected,
        ...this.supplierManagement.permissionsSelected,
      ];
    },

    permissionsSupplier(): string[] {
      return [
        ...this.supplier.permissionsSelected,
      ];
    },

    permissions(): string[] {
      if (this.isClientTypeMaster) {
        return this.permissionsMaster;
      }

      if (this.isClientTypeSupplier) {
        return this.permissionsSupplier;
      }

      return [];
    },

    clientParams(): any {
      if (this.isClientTypeMaster) {
        return {
          companyGroups: this.handleHierarchyBusinessLevelIdByLevel('companyGroup'),
          companies: this.handleHierarchyBusinessLevelIdByLevel('company'),
          establishments: this.handleHierarchyBusinessLevelIdByLevel('establishment'),
          masterId: this.masterId,
          __type: 'MasterClientParams',
        };
      }

      if (this.isClientTypeSupplier) {
        return {
          masters: this.supplier.mastersSelected,
          supplierId: this.supplierId,
          __type: 'SupplierClientParams',
        };
      }

      return null;
    },

    clientTypeOptions(): SelectOption[] {
      return [
        {
          optionLabel: this.$t('profileManagement.clientType.master') as string,
          optionValue: 'master',
        },
        {
          optionLabel: this.$t('profileManagement.clientType.supplier') as string,
          optionValue: 'supplier',
        },
      ];
    },
  },

  watch: {
    'formData.id': {
      immediate: true,
      handler(val: string | undefined): void {
        if (val) {
          this.handlePermissionIsRequired();
        }
      },
    },
    'bank.permissions': {
      handler(permissions: SelectOption[]): void {
        const hasPermissions = permissions.length > 0;
        if (hasPermissions) {
          this.bank.permissionsSelected = this.formData.permissions
            .filter((permission) => permissions.find(
              (option) => option.optionValue === permission,
            ));
        }
      },
    },
    'bankAdmin.permissions': {
      handler(permissions: SelectOption[]): void {
        const hasPermissions = permissions.length > 0;

        if (hasPermissions) {
          this.bankAdmin.permissionsSelected = this.formData.permissions
            .filter((permission) => permissions.find(
              (option) => option.optionValue === permission,
            ));
        }
      },
    },
    'supplierManagement.permissions': {
      handler(permissions: SelectOption[]): void {
        const hasPermissions = permissions.length > 0;

        if (hasPermissions) {
          this.supplierManagement.permissionsSelected = this.formData.permissions
            .filter((permission) => permissions.find(
              (option) => option.optionValue === permission,
            ));
        }
      },
    },
    'supplier.permissions': {
      handler(permissions: SelectOption[]): void {
        if (permissions.length > 0) {
          this.supplier.permissionsSelected = this.formData.permissions
            .filter((permission) => permissions.find(
              (option) => option.optionValue === permission,
            ));
        }
      },
    },
    'supplier.masters': {
      handler(masters: SelectOption[]): void {
        if (masters.length > 0) {
          const {
            masters: clientParamsMasters = [],
          } = this.formData.clientParams as SupplierParams;

          this.supplier.mastersSelected = clientParamsMasters
            .filter((masterId) => masters.find(
              (option) => option.optionValue === masterId,
            ));
        }
      },
    },
    'profileNameValidation.pending': {
      handler(pending: boolean): void {
        this.$emit('canSubmit', !pending);
      },
    },
  },

  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),

    handleHierarchyBusinessLevelIdByLevel(level: 'companyGroup' | 'company' | 'establishment'): string[] {
      return this.hierarchyBusinessLevels
        .filter((hierarchyBusinessLevel) => hierarchyBusinessLevel.level === level)
        .map((hierarchyBusinessLevel) => hierarchyBusinessLevel.id);
    },

    hierarchyBusinessLevelPayload(): void {
      if (this.formData.clientType === 'master') {
        const clientParams = this.formData.clientParams as MasterParams;

        clientParams.companyGroups.forEach((companyGroupId) => {
          this.hierarchyBusinessLevels.push({
            id: companyGroupId,
            level: 'companyGroup',
          });
        });

        clientParams.companies.forEach((companyId) => {
          this.hierarchyBusinessLevels.push({
            id: companyId,
            level: 'company',
          });
        });

        clientParams.establishments.forEach((establishmentId) => {
          this.hierarchyBusinessLevels.push({
            id: establishmentId,
            level: 'establishment',
          });
        });
      }
    },

    async getBankAdminPermissions() {
      const permissions = await ProfileManagementService.bankAdminPermissions();

      this.bankAdmin.permissions = permissions.map((permission) => ({
        optionLabel: this.$t(`profileManagement.form.bankAdminPermissionsOptions.${permission}`) as string,
        optionValue: permission,
      })).sort((x, y) => x.optionLabel.localeCompare(y.optionLabel));
    },

    async getBankPermissions() {
      const permissions = await ProfileManagementService.bankPermissions();

      this.bank.permissions = permissions.map((permission) => ({
        optionLabel: this.$t(`profileManagement.form.bankPermissionsOptions.${permission}`) as string,
        optionValue: permission,
      })).sort((x, y) => x.optionLabel.localeCompare(y.optionLabel));
    },

    async getSupplierManagementPermissions() {
      const permissions = await ProfileManagementService.supplierManagementPermissions();

      this.supplierManagement.permissions = permissions.map((permission) => ({
        optionLabel: this.$t(`profileManagement.form.supplierManagementPermissionsOptions.${permission}`) as string,
        optionValue: permission,
      })).sort((x, y) => x.optionLabel.localeCompare(y.optionLabel));
    },

    async getSupplierPermissions() {
      const permissions = await ProfileManagementService.supplierPermissions();

      this.supplier.permissions = permissions.map((permission) => ({
        optionLabel: this.$t(`profileManagement.form.supplierPermissionsOptions.${permission}`) as string,
        optionValue: permission,
      })).sort((x, y) => x.optionLabel.localeCompare(y.optionLabel));
    },

    async getSupplierMasters() {
      if (this.supplierMasters && Object.keys(this.supplierMasters).length > 0) {
        const data = await MasterService.getMastersNames(this.supplierMasters);
        try {
          this.supplier.masters = data.map((item) => ({
            optionLabel: item.name,
            optionValue: item.id,
          }) as SelectOption) || [];
        } catch (error) {
          const message = this.$t('profileManagement.alerts.failLoadCompanyGroups.message') as string;
          this.notifyError(message);
          console.log(error);
        }
      }
    },

    failRequest(message: string | undefined = undefined) {
      this.warningToast({
        text: message ?? this.$t('profileManagement.alerts.failRequest.message') as string,
      });
    },

    failRequestCreateOrUpdate(e: any) {
      let message;
      if (e.response.data.message === 'manager_profile_name_in_use') {
        message = this.$t('profileManagement.alerts.failProfileNameInUse.message') as string;
      }
      this.failRequest(message);
    },

    async validateForm(): Promise<boolean> {
      if (this.profileNameValidation.pending) return false;

      const form: any = this.$refs.form as VForm;

      const valid = await form.validate() as Promise<boolean>;

      return valid;
    },

    buildPayload() {
      const profilePayload = {
        id: this.payload?.id,
        name: this.profileNameValidation.profileName,
        clientParams: this.clientParams,
        permissions: this.permissions,
      };

      return profilePayload;
    },

    async submit(): Promise<void> {
      const valid = await this.validateForm();
      if (!valid) return;

      const payload = this.buildPayload();

      if (this.isUpdate) {
        await this.manageProfileUpdate(payload);
      } else {
        await this.manageProfileRegistration(payload);
      }

      this.$emit('finished');
    },

    async manageProfileRegistration(payload: ProfileManagement) {
      try {
        this.handleLoading(true);

        await ProfileManagementService.create(payload);

        this.successToast({
          text: this.$t('profileManagement.alerts.successCreate.message') as string,
        });
      } catch (e) {
        this.failRequestCreateOrUpdate(e as any);
      } finally {
        this.handleLoading(false);
      }
    },

    async manageProfileUpdate(payload: ProfileManagement) {
      try {
        this.handleLoading(true);

        if (!payload.id) {
          return;
        }

        await ProfileManagementService.update(payload.id, payload);

        this.successToast({
          text: this.$t('profileManagement.alerts.successUpdate.message') as string,
        });
      } catch (e) {
        this.failRequestCreateOrUpdate(e as any);
      } finally {
        this.handleLoading(false);
      }
    },

    handlePermissionIsRequired() {
      let hasPermissionSelected = false;

      if (this.formData.clientType === 'master') {
        hasPermissionSelected = [
          ...this.bank.permissionsSelected,
          ...this.bankAdmin.permissionsSelected,
          ...this.supplierManagement.permissionsSelected,
        ].length > 0;
      }

      if (this.formData.clientType === 'supplier') {
        hasPermissionSelected = [
          ...this.supplier.permissionsSelected,
        ].length > 0;
      }

      this.permissionIsRequired = !hasPermissionSelected;
    },

    notifyError(message: string) {
      this.warningToast({ text: message });
    },
  },
});
