var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("fieldset", { staticClass: "mt-2" }, [
    _c("legend", [
      _vm._v(_vm._s(_vm.$t("profileManagement.form.section.adminPermissions"))),
    ]),
    _c(
      "div",
      { staticClass: "p-fluid" },
      [
        _c("input-validation", {
          staticClass: "p-fluid p-grid",
          attrs: { rules: { required: _vm.isRequired } },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ errors }) {
                return _vm._l(2, function (un, idx) {
                  return _c(
                    "div",
                    { key: idx, staticClass: "p-col-6 p-field" },
                    _vm._l(
                      _vm.source.permissions.slice(
                        idx * _vm.permissionHalfLength,
                        un * _vm.permissionHalfLength
                      ),
                      function (permission, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "p-field-checkbox mt-2.5 mb-0",
                          },
                          [
                            _c("Checkbox", {
                              class: { "p-invalid": errors[0] },
                              attrs: {
                                id: permission.optionValue,
                                value: permission.optionValue,
                              },
                              on: { change: _vm.emitCheck },
                              model: {
                                value: _vm.source.permissionsSelected,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.source,
                                    "permissionsSelected",
                                    $$v
                                  )
                                },
                                expression: "source.permissionsSelected",
                              },
                            }),
                            _c(
                              "label",
                              { attrs: { for: permission.optionValue } },
                              [_vm._v(_vm._s(permission.optionLabel))]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                })
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }