var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "p-grid p-fluid" }, [
    _c("div", { staticClass: "p-col-12" }, [
      _c("div", { staticClass: "pb-2" }, [
        _c("span", { staticClass: "fs14 r-text-muted" }, [
          _vm._v(" " + _vm._s(_vm.$t("profileManagement.form.title")) + " "),
        ]),
      ]),
      _c(
        "div",
        [
          _c("input-validation", {
            attrs: {
              label: _vm.$t("profileManagement.form.profile.label"),
              name: _vm.$t("profileManagement.form.profile.label"),
              rules: {
                required: true,
                validateProfileName: {
                  isValid: _vm.data.valid,
                  pending: _vm.data.pending,
                },
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors, pending }) {
                  return [
                    _c(
                      "span",
                      { staticClass: "p-input-icon-right" },
                      [
                        pending
                          ? _c("i", { staticClass: "pi pi-spin pi-spinner" })
                          : _vm._e(),
                        _c("InputText", {
                          class: { "p-invalid": errors[0] },
                          attrs: {
                            placeholder: _vm.$t(
                              "profileManagement.form.profile.placeholder"
                            ),
                          },
                          on: { input: _vm.profileNameDebounce },
                          model: {
                            value: _vm.profileName,
                            callback: function ($$v) {
                              _vm.profileName = $$v
                            },
                            expression: "profileName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }