
import mixins from 'vue-typed-mixins';
import { SelectOption } from '@/types';

type Source = {
  permissions: SelectOption[];
  permissionsSelected: string;
}

export default mixins().extend({
  name: 'AdminPermissionSectionForm',

  props: {
    source: {
      type: Object as () => Source,
      required: true,
    },

    isRequired: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    permissionHalfLength() {
      return Math.round(this.source.permissions.length / 2);
    },
  },

  methods: {
    emitCheck() {
      this.$emit('onCheck');
    },
  },

});
