var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ProfileManagementHeaderSection", {
        on: {
          search: function ($event) {
            _vm.search = $event
          },
          onCreate: _vm.handleCreate,
        },
      }),
      _c("ProfileManagementFormModal", {
        attrs: {
          "form-payload": _vm.itemUpdateData,
          "show-modal": _vm.showModal,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showModal = $event
          },
          onSuccess: function ($event) {
            _vm.tableRefresh = true
          },
        },
      }),
      _c("ProfileManagementTable", {
        attrs: { search: _vm.search, refresh: _vm.tableRefresh },
        on: {
          "update:refresh": function ($event) {
            _vm.tableRefresh = $event
          },
          onEditItem: function ($event) {
            return _vm.editItem($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }