var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Form",
    [
      _c(
        "ValidationObserver",
        { ref: "form", attrs: { slim: "" } },
        [
          _c("ProfileNameSectionForm", {
            attrs: {
              source: _vm.formData.name,
              "client-type": _vm.formData.clientType,
            },
            on: {
              isValid: function ($event) {
                _vm.profileNameValidation.valid = $event
              },
              pending: function ($event) {
                _vm.profileNameValidation.pending = $event
              },
              profileName: function ($event) {
                _vm.profileNameValidation.profileName = $event
              },
            },
          }),
          !_vm.isUpdate && _vm.isMultipleProducts
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("input-validation", {
                    attrs: { rules: "required" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "p-formgroup-inline" },
                                _vm._l(
                                  _vm.clientTypeOptions,
                                  function (option, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "p-field-radiobutton mt-2",
                                      },
                                      [
                                        _c("RadioButton", {
                                          class: { "p-invalid": errors[0] },
                                          attrs: {
                                            id: option.optionLabel,
                                            name: "productType",
                                            value: option.optionValue,
                                          },
                                          model: {
                                            value: _vm.formData.clientType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "clientType",
                                                $$v
                                              )
                                            },
                                            expression: "formData.clientType",
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: option.optionLabel },
                                          },
                                          [_vm._v(_vm._s(option.optionLabel))]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      122576342
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            (_vm.isMultipleProducts ? _vm.isClientTypeSupplier : _vm.isSupplier)
              ? _c(
                  "div",
                  { key: "supplier" },
                  [
                    _vm.supplier.permissions.length > 0
                      ? _c("SupplierPortalSectionForm", {
                          attrs: { source: _vm.supplier },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.isMultipleProducts ? _vm.isClientTypeMaster : _vm.isMaster)
              ? _c(
                  "div",
                  { key: "master" },
                  [
                    _vm.supplierManagement.permissions.length > 0
                      ? _c("SupplierManagementPermissionSectionForm", {
                          attrs: {
                            source: _vm.supplierManagement,
                            "is-required": _vm.permissionIsRequired,
                          },
                          on: { onCheck: _vm.handlePermissionIsRequired },
                        })
                      : _vm._e(),
                    _vm.bank.permissions.length > 0
                      ? _c("InboundPortalPermissionSectionForm", {
                          attrs: {
                            source: _vm.bank,
                            "is-required": _vm.permissionIsRequired,
                          },
                          on: { onCheck: _vm.handlePermissionIsRequired },
                        })
                      : _vm._e(),
                    _vm.bankAdmin.permissions.length > 0
                      ? _c("AdminPermissionSectionForm", {
                          attrs: {
                            source: _vm.bankAdmin,
                            "is-required": _vm.permissionIsRequired,
                          },
                          on: { onCheck: _vm.handlePermissionIsRequired },
                        })
                      : _vm._e(),
                    _c("HierarchyBusinessLevelSectionForm", {
                      attrs: { source: _vm.hierarchyBusinessLevels },
                      on: {
                        "update:source": function ($event) {
                          _vm.hierarchyBusinessLevels = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }