import { ProfileManagement } from '@/resources/profile-management/types';
import HttpService from '../HttpService';

export type UpdateProfileManagement = ProfileManagement

class ProfileManagementService extends HttpService {
  private static baseURL = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/profile-management`;

  constructor() {
    super({ baseURL: ProfileManagementService.baseURL });
  }

  async create(payload: ProfileManagement): Promise<any> {
    return this.service.post('/', payload);
  }

  async update(id: string, payload: UpdateProfileManagement): Promise<any> {
    return this.service.put(`/${id}`, payload);
  }

  async find(search: string): Promise<any> {
    const params = new URLSearchParams([]);

    if (search) {
      params.append('search', search);
    }

    const { data } = await this.service.get('/', {
      params,
      cancelToken: this.cancelToken.token,
    });
    return data.data;
  }

  async bankAdminPermissions(): Promise<string[]> {
    const { data } = await this.service.get('/bank-admin-permissions');
    return data.data;
  }

  async bankPermissions(): Promise<string[]> {
    const { data } = await this.service.get('/bank-permissions');
    return data.data;
  }

  async supplierManagementPermissions(): Promise<string[]> {
    const { data } = await this.service.get('/supplier-management-permissions');
    return data.data;
  }

  async supplierPermissions(): Promise<string[]> {
    const { data } = await this.service.get('/supplier-permissions');
    return data.data;
  }

  async existName(name: string, clientType: string): Promise<any> {
    const params = new URLSearchParams([]);

    params.append('name', name);
    params.append('clientType', clientType);

    const { data } = await this.service.get('/exist-name', {
      params,
      cancelToken: this.cancelToken.token,
    });
    return data.data;
  }
}

export default new ProfileManagementService();
