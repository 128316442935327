
import Vue from 'vue';
import Table from '@/common/components/table/Table.vue';
import { HeaderCustomTable } from '@/types';
import locales from '@/resources/profile-management/locales';
import {
  ProfileManagement, MasterParams, SupplierParams,
} from '@/resources/profile-management/types';
import profileManagementService from '@/common/services/company-reborn/ProfileManagementService';

type PermissionProfileItem = {
  id: string;
  name: string;
  isMainOrganization: boolean;
  master?: {
    masterId: string;
    inboundPortal?: {
      permissions: string[];
    };
    admin?: {
      permissions: string[];
    };
    supplierManagement?: {
      permissions: string[];
    };
    hierarchyBusinessLevel?: {
      companyGroups: { id: string; name: string }[];
      companies: { id: string; name: string }[];
      establishments: { id: string; name: string }[];
    };
  };
  supplier?: {
    supplierId: string;
    supplierPortal: {
      permissions: string[];
    };
    masters: { id: string; name: string}[];
  };
};

export default Vue.extend({
  name: 'ProfileManagementTable',

  i18n: {
    messages: locales,
  },

  components: {
    CustomTable: Table,
  },

  props: {
    search: {
      type: String,
      required: false,
      default: '',
    },

    refresh: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      rows: [] as PermissionProfileItem[],
      header: {
        alias: [
          this.$t('profileManagement.tableProfiles.header.name'),
          this.$t('profileManagement.tableProfiles.header.affectedProducts'),
          this.$t('profileManagement.tableProfiles.header.permissionType'),
        ],
        columnsToShow: [
          'name',
          'affectedProducts',
          'hierarchyBusinessLevel',
        ],
      } as HeaderCustomTable,
    };
  },

  computed: {
    rowsData(): PermissionProfileItem[] {
      return this.rows;
    },
  },

  watch: {
    async search(search) {
      await this.fetchProfileManagement(search);
    },
    async refresh(refresh, oldRefresh) {
      if (refresh && !oldRefresh) {
        await this.fetchProfileManagement();
        this.$emit('update:refresh', false);
      }
    },
  },

  mounted() {
    this.fetchProfileManagement();
  },

  methods: {
    editItem(item: PermissionProfileItem): void {
      type ClientType = 'master' | 'supplier';

      let clientType: ClientType | undefined;
      let clientParams: MasterParams | SupplierParams | undefined;
      const { master, supplier } = item;

      if (master) {
        clientType = 'master';
        clientParams = {
          masterId: master.masterId,
          companyGroups: master.hierarchyBusinessLevel?.companyGroups.map((i) => i.id) ?? [],
          companies: master.hierarchyBusinessLevel?.companies.map((i) => i.id) ?? [],
          establishments: master.hierarchyBusinessLevel?.establishments.map((i) => i.id) ?? [],
        };
      } else if (supplier) {
        clientType = 'supplier';
        clientParams = {
          supplierId: supplier.supplierId,
          masters: supplier.masters.map((masterItem) => masterItem.id),
        };
      }

      if (!clientType || !clientParams) {
        return;
      }

      const data: ProfileManagement & { clientType: 'master' | 'supplier'} = {
        id: item.id,
        name: item.name,
        clientType,
        clientParams,
        permissions: [
          ...item?.master?.inboundPortal?.permissions ?? [],
          ...item?.master?.admin?.permissions ?? [],
          ...item?.master?.supplierManagement?.permissions ?? [],
          ...item?.supplier?.supplierPortal?.permissions ?? [],
        ],
      };
      this.$emit('onEditItem', data);
    },

    async fetchProfileManagement(search = '') {
      this.loading = true;
      this.rows = await profileManagementService.find(search);
      this.loading = false;
    },

    permissionTranslate(permission: string): string {
      return this.$t(`profileManagement.permissions.${permission.toLowerCase()}`) as string;
    },

    affectedProductsTranslate(item: any): string[] {
      const productsMaster = ['inboundPortal', 'admin', 'supplierManagement'];
      const productsSupplier = ['supplierPortal'];
      const products = [
        ...productsMaster,
        ...productsSupplier,
      ];

      const { master = {}, supplier = {} } = item;

      const productsAvailable = [
        master,
        supplier,
      ];

      if (productsAvailable.length === 0) {
        return [];
      }

      const affectedProducts = products.filter((product) => {
        const productAvailable = productsAvailable.find((available) => product in available);
        return productAvailable && productAvailable[product].permissions.length > 0;
      });
      return affectedProducts.map((product) => this.$t(`profileManagement.tableProfiles.affectedProduct.${product}`) as string);
    },

    masterPermissionsTypeTranslate(item: any): string[] {
      const types = ['companyGroups', 'companies', 'establishments'];
      const { hierarchyBusinessLevel } = item?.master ?? {};

      if (!hierarchyBusinessLevel) {
        return [];
      }

      const levels = types
        .filter((level) => (
          level in hierarchyBusinessLevel && hierarchyBusinessLevel[level].length > 0
        ))
        .map((level) => [level, hierarchyBusinessLevel[level].length]);

      return levels.map((type) => {
        const isPlural = type[1] > 1;
        const inflection = isPlural ? 'plural' : 'singular';
        const as = this.$t(`profileManagement.permissionsType.master.${type[0]}.${inflection}`) as string;
        return `${type[1]} ${as}`;
      });
    },

    supplierPermissionsTypeTranslate(item: any): string[] {
      const types = ['masters'];
      const { supplier } = item ?? {};

      if (!supplier) {
        return [];
      }

      const levels = types
        .filter((level) => (
          level in supplier && supplier[level].length > 0
        ))
        .map((level) => [level, supplier[level].length]);

      return levels.map((type) => {
        const isPlural = type[1] > 1;
        const inflection = isPlural ? 'plural' : 'singular';
        const as = this.$t(`profileManagement.permissionsType.supplier.${type[0]}.${inflection}`) as string;
        return `${type[1]} ${as}`;
      });
    },

    permissionsTypeTranslate(item: any): string[] {
      return [
        ...this.masterPermissionsTypeTranslate(item),
        ...this.supplierPermissionsTypeTranslate(item),
      ];
    },
  },
});
