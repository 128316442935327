
import mixins from 'vue-typed-mixins';
import { extend } from 'vee-validate';
import ManagerProfilesService from '@/common/services/company-reborn/ProfileManagementService';
import { debounce } from 'lodash';

export default mixins().extend({
  name: 'ProfileNameSectionForm',

  props: {
    source: {
      type: String,
      default: () => (''),
    },
    clientType: {
      type: String,
      default: () => ('master'),
    },
  },

  data() {
    return {
      data: {
        timerId: null as unknown as NodeJS.Timeout,
        value: '',
        valid: true,
        pending: false,
      },
      profileName: String(this.source),
    };
  },

  watch: {
    'data.pending': {
      handler(bool: boolean) {
        this.$emit('pending', bool);
      },
    },
    'data.valid': {
      handler(bool: boolean) {
        this.$emit('isValid', bool);
      },
    },
    'data.value': {
      handler(val: string) {
        this.$emit('profileName', val);
      },
    },
    clientType: {
      handler(val: string) {
        this.profileNameDebounce(val);
      },
    },
  },

  mounted() {
    extend('validateProfileName', {
      validate: async (_v, args) => {
        if ('isValid' in args && 'pending' in args) {
          const poll = (resolve: any) => {
            if (args.pending === false) {
              resolve();
            } else {
              setTimeout(() => poll(resolve), 400);
            }
          };
          await new Promise(poll);
          return args.isValid;
        }
        return false;
      },
      params: ['isValid', 'pending'],
      message: this.$t('profileManagement.form.__extraValidation.profileName') as string,
    });
  },

  methods: {
    async profileNameValidation(name: string): Promise<void> {
      if (name === this.source) {
        this.data.valid = true;
        return;
      }

      if (this.data.value === name) {
        return;
      }
      try {
        this.data.pending = true;
        const existName = await ManagerProfilesService.existName(name, this.clientType);
        this.data.value = name;
        this.data.valid = !existName;
        this.data.pending = false;
      } catch {
        // empty
      }
    },
    profileNameDebounce: debounce(
      // eslint-disable-next-line func-names
      async function (profileName) {
        // @ts-ignore
        this.profileNameValidation(profileName);
      },
      600,
    ),
  },

});
