var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    attrs: {
      "columns-data": _vm.header,
      data: _vm.rowsData,
      loading: _vm.loading,
    },
    scopedSlots: _vm._u([
      {
        key: "columns",
        fn: function ({ item, column }) {
          return [
            column === "name"
              ? _c("td", [_vm._v(" " + _vm._s(item.name) + " ")])
              : _vm._e(),
            column === "affectedProducts"
              ? _c(
                  "td",
                  _vm._l(
                    _vm.affectedProductsTranslate(item),
                    function (affectedProduct) {
                      return _c(
                        "span",
                        {
                          key: item.id + affectedProduct,
                          staticClass:
                            "profile-management-table-tag affected-product",
                        },
                        [_vm._v(_vm._s(affectedProduct))]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
            column === "hierarchyBusinessLevel"
              ? _c(
                  "td",
                  _vm._l(
                    _vm.permissionsTypeTranslate(item),
                    function (permissionType) {
                      return _c(
                        "span",
                        {
                          key: item.id + permissionType,
                          staticClass:
                            "profile-management-table-tag permission-type",
                        },
                        [_vm._v(_vm._s(permissionType))]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "actions",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.top",
                      value: _vm.$t("edit"),
                      expression: "$t('edit')",
                      modifiers: { top: true },
                    },
                  ],
                  staticClass: "action",
                  on: {
                    click: function ($event) {
                      return _vm.editItem(item)
                    },
                  },
                },
                [_c("i", { staticClass: "icon-r-edit" })]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }