
import { FormRef } from '@/types';
import { AccessPass } from '@/resources/access-pass/types';
import mixins from 'vue-typed-mixins';
import ProfileManagementForm from '@/resources/profile-management/components/forms/ProfileManagementForm.vue';

export default mixins().extend({
  name: 'ProfileManagementFormModal',

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    formPayload: {
      type: Object as () => AccessPass || null,
      required: false,
    },
  },

  components: {
    ProfileManagementForm,
  },

  data() {
    return {
      canSubmit: true,
    };
  },

  computed: {
    modal: {
      get(): boolean {
        return this.showModal;
      },
      set(value: boolean) {
        this.$emit('update:showModal', value);
      },
    },
    isUpdate(): boolean {
      const { formPayload } = this;
      return formPayload?.id !== undefined;
    },
    title(): string {
      let flag = 'profileManagement.formModal.create.title';
      if (this.isUpdate) {
        flag = 'profileManagement.formModal.update.title';
      }
      return this.$t(flag) as string;
    },
  },
  methods: {
    async confirm() {
      const form: any = this.$refs.form as FormRef;
      form.submit();
    },
    formFinished() {
      this.$emit('onSuccess');
      this.modal = false;
    },
  },
});
