
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import locales from '@/resources/profile-management/locales';
import ProfileManagementFormModal from '@/resources/profile-management/components/modals/ProfileManagementFormModal.vue';
import ProfileManagementTable from '@/resources/profile-management/components/tables/ProfileManagementTable.vue';
import ProfileManagementHeaderSection from '@/resources/profile-management/components/sections/ProfileManagementHeaderSection.vue';

import { ProfileManagementData } from './types';

export default mixins(NotificationMixin).extend({
  name: 'ProfileManagementView',
  i18n: {
    messages: locales,
  },
  components: {
    ProfileManagementHeaderSection,
    ProfileManagementTable,
    ProfileManagementFormModal,
  },
  data() {
    return {
      search: '',
      tableRefresh: false,
      showModal: false,
      itemUpdateData: {} as ProfileManagementData | null,
    };
  },

  watch: {
    showModal(showModal) {
      if (!showModal) {
        this.itemUpdateData = null;
      }
    },
  },

  methods: {
    handleCreate() {
      this.showModal = true;
    },
    editItem(item: ProfileManagementData) {
      this.itemUpdateData = item;
      this.showModal = true;
    },
  },
});
