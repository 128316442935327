
import mixins from 'vue-typed-mixins';
import locales from '@/resources/profile-management/locales';

export default mixins().extend({
  name: 'ProfileManagementHeaderSection',

  i18n: {
    messages: locales,
  },

  data: () => ({
    search: '',
  }),

  watch: {
    search(search: string, old: string) {
      const { length } = search.toString().trim();
      const { length: oldLength } = old.toString().trim();

      if (oldLength === 2 && length === 1) {
        this.$emit('search', '');
      }

      if (length === 0 || length > 1) {
        this.$emit('search', search);
      }
    },
  },

  methods: {
    btnCreate() {
      this.$emit('onCreate');
    },
  },
});
