
import CorporateControlService from '@/common/services/corporate-control/CorporateControlService';
import NotificationMixin from '@/mixins/NotificationMixin';
import { SelectOption } from '@/types';
import { CompanyGroup } from '@roit-intern/roit-company-interfaces';
import mixins from 'vue-typed-mixins';
import HierarchyBusinessLevelTableForm from './HierarchyBusinessLevelTableForm.vue';

type HierarchyBusinessLevels = {
  id: string;
  name: string;
  nationalRegister?: string;
  level: 'companyGroup' | 'company' | 'establishment';
}[];

type HierarchyBusinessLevelSelectOption = SelectOption & {
  companyGroupId?: string;
  companyId?: string;
  nationalRegister?: string;
  disabled?: boolean;
  alreadyEnteredBy?: 'companyGroup' | 'company';
  level: 'companyGroup' | 'company' | 'establishment';
};

export default mixins(NotificationMixin).extend({
  name: 'HierarchyBusinessLevelSectionForm',

  props: {
    source: {
      type: Array,
      default: () => ([]),
    },
  },

  components: {
    HierarchyBusinessLevelTableForm,
    HierarchyBusinessLevelDropdown: () => import('./HierarchyBusinessLevelDropdown.vue'),
  },

  data() {
    return {
      companyGroup: {
        options: [] as HierarchyBusinessLevelSelectOption[],
      },
      company: {
        options: [] as HierarchyBusinessLevelSelectOption[],
      },
      establishment: {
        options: [] as HierarchyBusinessLevelSelectOption[],
      },
      level: {
        options: [
          { label: 'Vincular a grupo', value: 'companyGroup' },
          // { label: 'Vincular a empresa', value: 'company' },
          // { label: 'Vincular a estabelecimento', value: 'establishment' },
        ],
        selected: 'companyGroup' as 'companyGroup' | 'company' | 'establishment',
      },
      hierarchyBusinessLevels: [] as HierarchyBusinessLevels,
      hierarchyBusinessLevelDropdownHasErrors: false,
    };
  },

  computed: {
    hierarchyBusinessSelected(): 'companyGroup' | 'company' | 'establishment' {
      return this.level.selected;
    },

    hierarchyBusinessDropdownLabel(): string {
      return this.$t(`profileManagement.form.${this.hierarchyBusinessSelected}.label`) as string;
    },

    hierarchyBusinessDropdownPlaceholder(): string {
      return this.$t(`profileManagement.form.${this.hierarchyBusinessSelected}.placeholder`) as string;
    },

    hierarchyBusinessDropdownOptions(): HierarchyBusinessLevelSelectOption[] {
      const { options } = this[this.hierarchyBusinessSelected];

      return options
        .filter(
          (option) => !this.hierarchyBusinessLevels
            .find((hierarchyBusiness) => (
              hierarchyBusiness.id === option.optionValue
              && hierarchyBusiness.level === option.level
            )),
        )
        .map((option) => {
          const data = {
            ...option,
          };

          if (data.level === 'company') {
            if (this.hierarchyBusinessLevels.find((item) => item.level === 'companyGroup' && item.id === data.companyGroupId)) {
              data.disabled = true;
              data.alreadyEnteredBy = 'companyGroup';
            }
          }

          if (data.level === 'establishment') {
            const company = this.company.options.find(
              (item) => item.optionValue === data.companyId,
            );
            const hasCompanyGroupInUse = this.hierarchyBusinessLevels.find((item) => item.level === 'companyGroup' && item.id === company?.companyGroupId);
            const hasCompanyInUse = this.hierarchyBusinessLevels.find((item) => item.level === 'company' && item.id === data?.companyId);

            if (hasCompanyGroupInUse || hasCompanyInUse) {
              data.disabled = true;
              data.alreadyEnteredBy = hasCompanyGroupInUse ? 'companyGroup' : 'company';
            }
          }

          data.optionDisabled = data.disabled;

          return data;
        });
    },

    hierarchyBusinessDropdownSource: {
      get(): { options: HierarchyBusinessLevelSelectOption[]; selected: string | null } {
        const options = this.hierarchyBusinessDropdownOptions;
        return {
          options,
          selected: null,
        };
      },
    },
  },

  async mounted() {
    await Promise.all([
      this.loadCompanyGroups(),
      this.loadCompanies(),
      this.loadEstablishments(),
    ]);

    this.hierarchyBusinessLevels = (this.source as HierarchyBusinessLevels)
      .map(
        (hierarchyBusinessLevel) => {
          const option = this[hierarchyBusinessLevel.level].options.find(
            (item) => item.optionValue === hierarchyBusinessLevel.id,
          );

          return {
            ...hierarchyBusinessLevel,
            name: option?.optionLabel || '',
            nationalRegister: option?.nationalRegister || '',
          };
        },
      );
  },

  methods: {
    async loadCompanyGroups() {
      try {
        const companyGroups = await CorporateControlService.findCompanyGroupsByMaster();
        const options = companyGroups.filter((item: CompanyGroup) => item.name)
          .map((comp: CompanyGroup) => ({
            ...comp,
            name: comp.id,
            optionValue: comp.id,
            optionLabel: comp.name,
            level: 'companyGroup',
          })) as HierarchyBusinessLevelSelectOption[];

        this.companyGroup.options = options || [];
      } catch (error) {
        const message = this.$t('profileManagement.alerts.failLoadCompanyGroups.message') as string;
        this.notifyError(message);
        console.log(error);
      }
    },

    async loadCompanies() {
      try {
        const fields = ['companyGroupId', 'name', 'companyName', 'nationalRegister'];
        const { companies } = await CorporateControlService.findCompaniesByMaster({ fields });
        const options = companies.filter((item) => item.name)
          .map((comp) => ({
            ...comp,
            name: comp.companyId,
            optionValue: comp.id,
            optionLabel: comp.name,
            level: 'company',
          })) as HierarchyBusinessLevelSelectOption[];

        this.company.options = options || [];
      } catch (error) {
        const message = this.$t('profileManagement.alerts.failLoadCompanies.message') as string;
        this.notifyError(message);
        console.log(error);
      }
    },

    async loadEstablishments() {
      try {
        const fields = ['companyId', 'name', 'tradeName', 'companyName', 'nationalRegister'];

        const { establishments } = await CorporateControlService.findEstablishmentsByMaster({
          fields,
        });
        const options = establishments.filter((item) => item.name)
          .map((comp) => {
            const partialCnpj = comp.nationalRegister
              .substring(comp.nationalRegister.length - 6)
              .replace(/(\d{4})(\d{2})/g, '$1-$2');

            return {
              ...comp,
              name: `${comp.name} (${partialCnpj})`,
              optionValue: comp.id,
              optionLabel: `${comp.name} (${partialCnpj})`,
              level: 'establishment',
            };
          }) as HierarchyBusinessLevelSelectOption[];

        this.establishment.options = options || [];
      } catch (error) {
        const message = this.$t('profileManagement.alerts.failLoadEstablishments.message') as string;
        this.notifyError(message);
        console.log(error);
      }
    },

    addBusinessHierarchyPermission() {
      const { selected, options } = this.hierarchyBusinessDropdownSource;
      if (!selected) return;

      const option = options.find((item) => item.optionValue === selected);
      const notHasPermission = !this.hierarchyBusinessLevels.find(
        (hierarchyBusiness) => (
          hierarchyBusiness.id === option?.optionValue
          && hierarchyBusiness.level === option?.level
        ),
      );

      if (notHasPermission) {
        const {
          optionValue,
          optionLabel,
          nationalRegister,
        } = option as HierarchyBusinessLevelSelectOption;

        const permission = {
          id: optionValue,
          name: optionLabel,
          nationalRegister,
          level: this.hierarchyBusinessSelected,
        };
        this.hierarchyBusinessLevels.push(permission);
      }
    },

    notifyError(message: string) {
      this.warningToast({ text: message });
    },
  },

  watch: {
    hierarchyBusinessLevels: {
      deep: true,
      handler(hierarchyBusinessLevels) {
        this.$emit('update:source', hierarchyBusinessLevels);
      },
    },
  },
});
