import mixins from 'vue-typed-mixins';

export default mixins().extend({
  methods: {
    businessHierarchyTranslate(level: string): string {
      switch (level) {
        case 'companyGroup':
          return this.$t('profileManagement.permissionsType.master.companyGroups.singular') as string;
        case 'company':
          return this.$t('profileManagement.permissionsType.master.companies.singular') as string;
        case 'establishment':
          return this.$t('profileManagement.permissionsType.master.establishments.singular') as string;
        default:
          return '';
      }
    },
  },
});
